import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button, PrimaryButton, UIKitProvider, BUTTON_SIZE } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <h2 {...{
      "id": "basic-button"
    }}>{`Basic Button`}</h2>
    <Props of={Button} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
    Small
    <Button size={BUTTON_SIZE.SMALL} mdxType="Button">
      Click Me
    </Button>
    Default/Medium
    <Button mdxType="Button">
        Click Me
    </Button>
    </UIKitProvider>
    <h3 {...{
      "id": "with-icon"
    }}>{`With icon`}</h3>
    <UIKitProvider mdxType="UIKitProvider">
    Small
    <Button size={BUTTON_SIZE.SMALL} icon="add" mdxType="Button">
        Click Me
    </Button>
    Medium
    <Button icon="add" mdxType="Button">
        Click Me
    </Button>
    </UIKitProvider>
    <h3 {...{
      "id": "disabled"
    }}>{`Disabled`}</h3>
    <UIKitProvider mdxType="UIKitProvider">
    Small
    <Button size={BUTTON_SIZE.SMALL} disabled mdxType="Button">
        Click Me
    </Button>
    Medium
    <Button disabled mdxType="Button">
        Click Me
    </Button>
    </UIKitProvider>
    <h2 {...{
      "id": "primary-button"
    }}>{`Primary Button`}</h2>
    <Props of={PrimaryButton} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
    Small
    <PrimaryButton size={BUTTON_SIZE.SMALL} mdxType="PrimaryButton">
      Click Me
    </PrimaryButton>
    Default/Medium
    <PrimaryButton mdxType="PrimaryButton">
        Click Me
    </PrimaryButton>
    </UIKitProvider>
    <h3 {...{
      "id": "with-icon-1"
    }}>{`With icon`}</h3>
    <UIKitProvider mdxType="UIKitProvider">
    Small
    <PrimaryButton size={BUTTON_SIZE.SMALL} icon="add" mdxType="PrimaryButton">
        Click Me
    </PrimaryButton>
    Medium
    <PrimaryButton icon="add" mdxType="PrimaryButton">
        Click Me
    </PrimaryButton>
    </UIKitProvider>
    <h3 {...{
      "id": "disabled-1"
    }}>{`Disabled`}</h3>
    <UIKitProvider mdxType="UIKitProvider">
    Small
    <PrimaryButton size={BUTTON_SIZE.SMALL} disabled mdxType="PrimaryButton">
        Click Me
    </PrimaryButton>
    Medium
    <PrimaryButton disabled mdxType="PrimaryButton">
        Click Me
    </PrimaryButton>
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      